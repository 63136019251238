import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import customStorageDefinition from '../customStorageDefinition';
import { InitResponse } from '@src/api/useInit';

interface InitResponseState {
  _hasInitResponseStoreHydrated: boolean;
  setHasHydrated: (hasHydrated: boolean) => void;

  initParams: string | null;
  setInitParams: (initString: string) => void;

  client: InitResponse['client'] | null;
  engines: InitResponse['engines'] | null;
  currency: InitResponse['currency'] | null;
  options: InitResponse['options'] | null;
  termsAndConditions: InitResponse['termsAndConditions'] | null;
  setInitResponse: (initObj: {
    client: InitResponse['client'] | null;
    engines: InitResponse['engines'] | null;
    currency: InitResponse['currency'] | null;
    options: InitResponse['options'] | null;
    termsAndConditions: InitResponse['termsAndConditions'] | null;
  }) => void;
}

const useInitResponseStore = create<InitResponseState>()(
  persist(
    (set) => ({
      _hasInitResponseStoreHydrated: false,
      setHasHydrated: (hasHydrated) => {
        set({
          _hasInitResponseStoreHydrated: hasHydrated,
        });
      },

      initParams: null,
      setInitParams: (initString) => set({ initParams: initString }),

      client: null,
      currency: null,
      engines: null,
      options: null,
      termsAndConditions: null,
      setInitResponse: (initObj) => set(initObj),
    }),
    {
      name: 'init-response-persistent-storage',
      storage: createJSONStorage(() => customStorageDefinition),
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    }
  )
);

export default useInitResponseStore;
