import { Heading, Tooltip, Typography } from '@kaboodle-solutions/design-library';

import { BasketContent } from '@src/components/Basket';
import { PaymentTypeHandle } from '@src/constants';
import eCommTracking from '@src/lib/tracking/eCommTracking';
import styles from './basketSummary.module.scss';
import { useBasketQuery } from '@src/api/useBasket';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { useGetPaymentIntent } from '@src/hooks/usePaymentIntent/useGetPaymentIntent';
import { useGetPaymentOptions } from '@src/api/usePaymentOptions';
import { useTranslation } from 'react-i18next';

interface BasketSummaryProps {
  bookingOrPaymentRef?: string | null;
}

const BasketSummary = ({ bookingOrPaymentRef }: BasketSummaryProps) => {
  const { t } = useTranslation(['basket', 'common']);
  const { format } = useCurrencyFormatter();
  const { data: basketData, status } = useBasketQuery();
  const { data: paymentIntent, isFetching: isPiFetching, isLoading: isPiLoading } = useGetPaymentIntent();
  const { data: paymentOptions } = useGetPaymentOptions();
  if (status !== 'success' && !basketData) return null;

  const basket = basketData?.data;
  const basketDataDiscounts = basket?.discounts;
  const paymentPlan =
    paymentIntent?.paymentType === PaymentTypeHandle.PAYMENT_PLAN
      ? paymentOptions?.find((option) => option.handle === PaymentTypeHandle.PAYMENT_PLAN)
      : null;

  const paidTodayValue =
    paymentIntent?.status === 'succeeded' && (paymentIntent?.amount ?? 0) > 0 ? paymentIntent?.amount : 0;

  const paymentPlanSurcharge = paymentPlan?.surcharge ?? 0;
  const deliveryTotal = basket?.calculated?.total.delivery ?? 0;
  const basketValue = basket?.calculated?.total.value ?? 0;
  const bookingFeeTotal = basket?.calculated?.total.bookingFee ?? 0;
  const protectionPlanActive = basket?.protectionPlan?.active;
  const protectionPlanTotal = protectionPlanActive ? basket?.protectionPlan?.price.value ?? 0 : 0;

  const basketTotal = basketValue + paymentPlanSurcharge;

  // Tracking
  if (basket && bookingOrPaymentRef && !isPiFetching && !isPiLoading) {
    eCommTracking.purchase.sendBookingSuccessCartEvent(basket, bookingOrPaymentRef, paymentIntent);
  }

  return (
    <section className={styles.basketSummary_container}>
      <div className={styles.basketSummary_header}>
        <Heading level="h4" isBold>
          {t('title')}
        </Heading>
      </div>
      <hr />
      <BasketContent isBasketOpen={false} basketMode="checkout" testId="basketCart-content" />
      {basketDataDiscounts && (
        <>
          <hr />
          <div className={styles.basketSummary_discounts}>
            <Typography tag="span" isBold>
              {t('basket:discounts')}
            </Typography>
            {basketDataDiscounts.map((discount, index) => (
              <div key={`${discount.code}-${index}`} className={styles.basketSummary_discountsItem}>
                <Typography tag="span">{discount.code}</Typography>
                <Typography tag="span">{`-${format(discount.amount)}`}</Typography>
              </div>
            ))}
          </div>
        </>
      )}
      <hr />
      <div className={styles.basketSummary_footer}>
        {deliveryTotal > 0 && (
          <>
            <div className={styles.basketSummary_footerCalculations}>
              <Typography tag="span" isBold>
                {t('basket:basketFees')}
              </Typography>
              <Typography tag="span" isBold>
                {format(deliveryTotal)}
              </Typography>
            </div>
            <hr />
          </>
        )}
        {protectionPlanActive && (
          <>
            <div className={styles.basketSummary_footerCalculations}>
              <Typography tag="span" customClass={styles.basketSummary_footerCalculationsText}>
                {t('basket:refundProtection')}
              </Typography>
              <Typography tag="span" customClass={styles.basketSummary_footerCalculationsText}>
                {format(protectionPlanTotal)}
              </Typography>
            </div>
          </>
        )}
        {paymentPlan && (
          <div className={styles.basketSummary_footerCalculations}>
            <Typography tag="span" isBold>
              {t('basket:prePaymentPlanFee')}
            </Typography>
            <Typography tag="span" isBold>
              {format(paymentPlanSurcharge)}
            </Typography>
          </div>
        )}
        {(protectionPlanActive || paymentPlan) && <hr />}
        <div className={styles.basketSummary_footerTotalSum}>
          <Typography tag="span" isBold>
            {t('common:words.totalPrice')}
          </Typography>
          <Typography tag="span" isBold>
            {format(basketTotal)}
          </Typography>
        </div>
        <div className={styles.basketSummary_footerTotalBookingFees}>
          <Typography tag="span" customClass={styles.basketSummary_footerBookingFeesText}>
            {t('basket:incl')} {t('basket:bookingFees')} {t('common:words.of')} {format(bookingFeeTotal)}
            <Tooltip tipDesc={t('basket:bookingFeeTooltip')} />
          </Typography>
        </div>
        {paidTodayValue > 0 && (
          <div className={styles.basketSummary_footerPaidToday}>
            <Typography tag="span" customClass={styles.basketSummary_footerPaidTodayLabel}>
              {t('basket:paidToday')}
            </Typography>
            <Typography tag="span" customClass={styles.basketSummary_footerPaidTodayCost}>
              {format(paidTodayValue)}
            </Typography>
          </div>
        )}
      </div>
    </section>
  );
};

export default BasketSummary;
