import { ENGINE_NAV_DATA, HTTP_STATUS } from '@src/constants';
import { ErrorBoundaryArgs, useErrorBoundary } from '@src/components/ErrorBoundary';
import {
  QK_ACCOMMODATIONS,
  QK_APP_BASKET,
  QK_APP_BASKET_MUTATION,
  QK_EXTRAS,
  QK_GET_PAYMENT_OPTIONS,
  QK_SHOW_3_DOTS_LOADING,
} from '../queryKeys';
import { useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { BPAPI } from '@src/api/mockedApi';
import { BaseBasket } from './useBasket.types';
import { ExpiredBasketModalContent } from '@src/components/ModalContents/ExpiredBasketModalContent';
import { UpdateBasketRequestBody } from '@src/interfaces/engineBasketPutRequest';
import { handleCheckCurrentStep } from './useBasket.helper';
import { includesMutationOrQueryKey } from '@src/lib/includesMutationOrQueryKey';
import { AxiosError, isAxiosError } from 'axios';
import { queryClient } from '../queryClient';
import { setSentryTag } from '@src/lib/sentry/sentryUtils';
import { useBasketExpiryTimerStore } from '@src/store/useBasketExpiryTimerStore';
import { useBasketStore } from '@src/store/useBasketStore';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useLocation } from 'react-router-dom';
import { useModalStore } from '@src/store/useModalStore';
import { useNavStepperStages } from '@src/store/useNavStepperStages';
import { useNavigateWithQueryParams } from '@src/hooks/useNavigateWithQueryParams/useNavigateWithQueryParams';
import { useShallow } from 'zustand/react/shallow';
import displayBasketErrors from '@src/lib/errorHandling/displayBasketErrors';
import { KapiEngineHandlesOptions } from '@src/interfaces/engineData';
import { ReactQueryError, isReactQueryError } from '@src/lib/errorHandling/ReactQueryError';
import { axiosRequestWithSentryReporting } from '@src/lib/axiosRequestWithSentryReporting/axiosRequestWithSentryReporting';

const commonOptions = {
  skipErrorResponsesReporting: [410],
};
const nowTime = new Date().getTime();
const getBasket = () => BPAPI.get<BaseBasket | null>(`/basket?t=${nowTime}`);
const putBasket = axiosRequestWithSentryReporting(
  (payload: Partial<UpdateBasketRequestBody>, currencyCode: string | undefined) =>
    BPAPI.put<BaseBasket>('/basket', { ...payload, currencyCode }),
  commonOptions
);
const patchBasket = axiosRequestWithSentryReporting(
  (payload: Partial<UpdateBasketRequestBody>) => BPAPI.patch<BaseBasket>('/basket', payload),
  commonOptions
);

function handleBasketError({
  error,
  showBoundary,
}: {
  error: unknown;
  showBoundary: (error: ErrorBoundaryArgs) => void;
}) {
  if (!isAxiosError(error) && !isReactQueryError(error)) return;

  if (handleBasketExpiredError(error)) return;

  if (error.response && error.response.status >= HTTP_STATUS.BadRequest) {
    showBoundary({ error: error.response.statusText, template: 'basketError' });
  }
}

export function handleBasketExpiredError(error: unknown | AxiosError | ReactQueryError) {
  // Check it's error type and error response
  if (!(isAxiosError(error) || isReactQueryError(error)) || error.response?.status !== HTTP_STATUS.Gone) {
    return false;
  }

  const { openModal, status: modalStatus } = useModalStore.getState();
  // If no modal open, open the modal
  if (modalStatus !== 'open') {
    openModal(<ExpiredBasketModalContent />, false);
  }

  return true;
}

export const useBasketQuery = (enabled = true) => {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { showBoundary } = useErrorBoundary();
  const { engines } = useInitResponseStore();
  const location = useLocation();
  const { generateSteps, steps } = useNavStepperStages();
  const { basketId, setBasketId, setBasketExpiryTimer } = useBasketExpiryTimerStore(
    useShallow((state) => ({
      basketId: state.basketId,
      setBasketId: state.setBasketId,
      setBasketExpiryTimer: state.setTimeRemaining,
    }))
  );

  const { data, error, isError, isSuccess, isPlaceholderData, ...rest } = useQuery({
    queryKey: [QK_APP_BASKET, QK_SHOW_3_DOTS_LOADING],
    queryFn: getBasket,
    enabled,
    staleTime: Infinity,
    retry: (failureCount, error) => {
      if ((isAxiosError(error) || isReactQueryError(error)) && error.response?.status === HTTP_STATUS.Gone) {
        return false;
      }
      return failureCount <= 2;
    },
  });

  const basketIdChanged = basketId !== data?.data?.id;
  if (data?.data?.customerDetails?.id) {
    setSentryTag('customer_id', data?.data?.customerDetails?.id);
  }

  useEffect(() => {
    if (
      data?.status === HTTP_STATUS.Ok &&
      isSuccess &&
      !isPlaceholderData &&
      data?.data?.calculated?.timeRemaining &&
      basketIdChanged &&
      data.data.id
    ) {
      handleCheckCurrentStep(steps, navigateWithQueryParams);
      setBasketId(data.data.id);
      setSentryTag('basket_id', data.data.id);
      setBasketExpiryTimer(data.data.calculated.timeRemaining);
    }
  }, [
    basketIdChanged,
    data,
    isSuccess,
    isPlaceholderData,
    setBasketExpiryTimer,
    setBasketId,
    navigateWithQueryParams,
    steps,
  ]);

  useEffect(() => {
    if (isError) {
      handleBasketError({
        error,
        showBoundary,
      });
    }
  }, [isError, error, showBoundary]);

  // If the basket has a booking ref, send the user off to the booking success page except if already on a success page
  useEffect(() => {
    if (data?.data?.bookingRef && location.pathname !== ENGINE_NAV_DATA['CHECKOUT_STATUS'].route) {
      navigateWithQueryParams(ENGINE_NAV_DATA['BOOKING_STATUS'].route);
    }
  }, [data?.data?.bookingRef, navigateWithQueryParams, location.pathname]);

  useEffect(() => {
    generateSteps(data?.data, engines);
  }, [data?.data, generateSteps, engines]);

  if (data?.status === HTTP_STATUS.NoContent) {
    return {
      data: null,
      error,
      isError,
      isSuccess,
      isPlaceholderData,
      ...rest,
    };
  }

  return {
    data,
    error,
    isError,
    isSuccess,
    isPlaceholderData,
    ...rest,
  };
};

export const useBasketMutation = (args?: { patch?: boolean; showBasketErrorsOnEngine?: KapiEngineHandlesOptions }) => {
  const currency = useInitResponseStore((state) => state.currency);
  const currencyCode = currency?.code;
  const setBasketStore = useBasketStore((state) => state.setBasketStore);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { steps, generateSteps } = useNavStepperStages();
  const { engines } = useInitResponseStore();

  // Checks current step every time steps are updated
  useEffect(() => {
    handleCheckCurrentStep(steps, navigateWithQueryParams);
  }, [steps, navigateWithQueryParams]);

  return useMutation({
    mutationKey: [QK_APP_BASKET_MUTATION, QK_SHOW_3_DOTS_LOADING],
    mutationFn: (payload: Partial<UpdateBasketRequestBody>) => {
      if (args?.patch) return patchBasket(payload);
      return putBasket(payload, currencyCode);
    },
    onSuccess: async (data) => {
      if (data?.data?.id) {
        setSentryTag('basket_id', data.data.id);
      }

      setBasketStore({ mutateBasketStatus: 'success' });
      queryClient.setQueriesData(
        {
          predicate: (query) => includesMutationOrQueryKey(query, QK_APP_BASKET),
        },
        data
      );

      await queryClient.invalidateQueries({
        predicate: (query) => includesMutationOrQueryKey(query, QK_GET_PAYMENT_OPTIONS),
      });
      await queryClient.invalidateQueries({
        predicate: (query) =>
          includesMutationOrQueryKey(query, QK_EXTRAS) || includesMutationOrQueryKey(query, QK_ACCOMMODATIONS),
      });

      // Regenerates Stepper when validationErrors change
      generateSteps(data?.data, engines);

      // Displays basket errors and engine based validationErrors
      displayBasketErrors(data?.data, args?.showBasketErrorsOnEngine);
    },
    onError: (error) => {
      handleBasketExpiredError(error);
      setBasketStore({ mutateBasketStatus: 'error' });
    },
    onMutate: () => {
      setBasketStore({ mutateBasketStatus: 'pending' });
    },
  });
};
