import { HTTP_STATUS, RESTART_ROUTE } from '@src/constants';
import { QK_INIT, QK_SHOW_KABOODLE_LOADING } from '@src/api/queryKeys';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { getInit } from '@src/api/useInit';
import { isAxiosError } from 'axios';
import tracking from '@src/lib/tracking';
import useBpTokenStore from '@src/store/useBpTokenStore';
import { useErrorBoundary } from '@src/components/ErrorBoundary';
import { useFirebaseAuth } from '../useFirebaseAuth/useFirebaseAuth';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useLoadingStore } from '@src/store/useLoadingStore';
import usePaymentMethodStore from '@src/store/persistent/selectedPaymentMethod/usePaymentMethodStore';
import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import { useStripeReturnUrlStore } from '@src/store/persistent/useStripeReturnUrlStore';
import { useTranslation } from 'react-i18next';

const REQUIRED_PARAMS = ['t', 'c', 'k'];

export function checkRequiredParams(searchParams: URLSearchParams): boolean {
  const hasRequiredParams = REQUIRED_PARAMS.every((param) => searchParams.has(param));
  const hasEitherPorM = searchParams.has('p') || searchParams.has('m');

  return hasRequiredParams && hasEitherPorM;
}

/**
 * This function is responsible for handling the init process.
 * On first load of the application, we assume that the url
 * contains the correct parameters for authenticating with BPAPI.
 * On subsequent loads, we check if the user has previously
 * authenticated via BPAPI. If so, we use the stored token.
 *
 * This process is intended to only run once per session. Any
 * init data is stored on the client locally for use in other locations.
 */
function useInitProcess() {
  const { t } = useTranslation('errors');
  const { showBoundary } = useErrorBoundary();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const hasFetchedInitThisInstance = useRef(false);
  const hasSetGtmIdsThisInstance = useRef(false);
  const setIsAppInitializing = useLoadingStore((state) => state.setIsAppInitializing);
  const { clearStripeReturnUrlStore } = useStripeReturnUrlStore();
  const { setPaymentMethod } = usePaymentMethodStore();
  const {
    setInitResponse,
    setInitParams,
    _hasInitResponseStoreHydrated,
    initParams,
    options: initResponseStoreOptions,
  } = useInitResponseStore();
  const { setBpToken, bpTokenExtractedUnverified, _hasBpTokenStoreHydrated } = useBpTokenStore(
    useShallow((state) => ({
      bpToken: state.bpToken,
      setBpToken: state.setBpToken,
      bpTokenExtractedUnverified: state.bpTokenExtractedUnverified,
      _hasBpTokenStoreHydrated: state._hasBpTokenStoreHydrated,
    }))
  );

  const tenantId = useMemo(
    () => searchParams.get('t') || bpTokenExtractedUnverified?.t,
    [bpTokenExtractedUnverified?.t, searchParams]
  );

  const { isFirebaseAuthLoading, isFirebaseAuthReady, isFirstBpTokenHeaderReady } = useFirebaseAuth(tenantId);

  useEffect(() => {
    if (isFirebaseAuthReady && isFirstBpTokenHeaderReady) {
      setIsAppInitializing(false);
    }
  }, [setIsAppInitializing, isFirebaseAuthReady, isFirstBpTokenHeaderReady]);

  // If there are search parameters, we fire off the mutation
  // to authenticate with BPAPI. This takes priority over a saved token.
  const hasRequiredParams = checkRequiredParams(searchParams);
  const queryStringInUrl = location.search;
  const currentRoute = location.pathname;
  const isForcedRestart = currentRoute === RESTART_ROUTE;
  const urlParamsNotMatchSavedParams = initParams !== queryStringInUrl;

  const enableInitRequest =
    isForcedRestart ||
    (hasRequiredParams &&
      _hasInitResponseStoreHydrated &&
      urlParamsNotMatchSavedParams &&
      !hasFetchedInitThisInstance.current);

  const {
    data: initResponse,
    isError,
    error,
    isFetched: isInitFetchedAfterMount,
    isSuccess: isInitSuccessful,
    isLoading: isInitLoading,
  } = useQuery({
    queryKey: [QK_SHOW_KABOODLE_LOADING, QK_INIT, String(searchParams)],
    queryFn: () => getInit(searchParams),
    enabled: enableInitRequest && isFirebaseAuthReady,
    retry: (failureCount, error) => {
      if (isAxiosError(error) && error.response?.status === HTTP_STATUS.Gone) {
        return false;
      }
      return failureCount <= 2;
    },
  });

  const overallLoading = isFirebaseAuthLoading || isInitLoading;

  if (error && isError) {
    setBpToken(null);
    setInitResponse({
      client: null,
      engines: null,
      currency: null,
      options: null,
      termsAndConditions: null,
    });

    if (isAxiosError(error) && error.response?.status === HTTP_STATUS.Gone) {
      showBoundary({ error: error.message, template: 'packageNotOnSale' });
    } else {
      showBoundary({ error: error.message, template: 'default' });
    }
  }

  const response = {
    bpToken: bpTokenExtractedUnverified,
    initResponse,
    isInitLoading: overallLoading,
    isInitSuccessful,
    isFirebaseAuthReady,
    isFirstBpTokenHeaderReady,
  };

  // Track Client GTM on every refresh
  useEffect(() => {
    if (!overallLoading && (!!initResponse || !!initResponseStoreOptions) && !hasSetGtmIdsThisInstance.current) {
      const clientGtmIds: string[] =
        (initResponse?.data?.options || initResponseStoreOptions)?.GTM_IDS?.split(',') || [];
      if (clientGtmIds && clientGtmIds.length > 0) {
        clientGtmIds.forEach((clientGtmId) => {
          tracking.initTracking(clientGtmId);
        });
      }
      hasSetGtmIdsThisInstance.current = true;
    }
  }, [initResponse, initResponseStoreOptions, overallLoading]);

  // Set store values
  if (isInitSuccessful && isInitFetchedAfterMount && !!initResponse && !hasFetchedInitThisInstance.current) {
    if (!initResponse?.headers.bp) {
      showBoundary({ error: t('noTokensFound'), template: 'noTokensFound' });
    }

    setBpToken(initResponse?.headers.bp);
    setInitResponse({
      client: initResponse?.data?.client,
      engines: initResponse?.data?.engines,
      currency: initResponse?.data?.currency,
      options: initResponse?.data?.options,
      termsAndConditions: initResponse?.data?.termsAndConditions,
    });
    setInitParams(location.search);
    clearStripeReturnUrlStore();
    // reset payment method store back to default
    setPaymentMethod();

    hasFetchedInitThisInstance.current = true;

    return response;
  }

  // If there are no search parameters, we check if the user has
  // previously authenticated with BPAPI. If not, we show an error boundary.
  const noBpToken = !bpTokenExtractedUnverified;
  if (_hasBpTokenStoreHydrated && noBpToken && !enableInitRequest) {
    showBoundary({ error: t('noTokensFound'), template: 'noTokensFound' });
  }

  return response;
}

export { useInitProcess };
