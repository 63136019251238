import { QK_APP_BASKET, QK_APP_BASKET_MUTATION, QK_SHOW_3_DOTS_LOADING } from '@src/api/queryKeys';

import { BPAPI } from '@src/api/mockedApi';
import { RESTART_ROUTE } from '@src/constants';
import { includesMutationOrQueryKey } from '@src/lib/includesMutationOrQueryKey';
import { queryClient } from '@src/api/queryClient';
import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useNavigateWithQueryParams } from '../useNavigateWithQueryParams/useNavigateWithQueryParams';
import { type BaseBasket } from '@src/api/useBasket/useBasket.types';

type handleRestartBasketArgType = {
  callback?: () => void;
  skipBasketDeletionBeforeRestart?: boolean;
};

type useRestartBookingArgsType = {
  basketData: BaseBasket | null | undefined;
};

function useRestartBooking({ basketData }: useRestartBookingArgsType) {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const navigate = useNavigate();

  const { mutateAsync: deleteBasketRequest } = useMutation({
    mutationKey: [QK_SHOW_3_DOTS_LOADING, QK_APP_BASKET_MUTATION, 'RESTART_BASKET'],
    mutationFn: async () => BPAPI.delete('/basket'),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => includesMutationOrQueryKey(query, QK_APP_BASKET),
      });
    },
  });

  const handleRestartBasket = useCallback(
    async ({ callback, skipBasketDeletionBeforeRestart }: handleRestartBasketArgType = {}) => {
      if (callback) {
        callback();
      }

      if (basketData && !basketData?.bookingRef && !skipBasketDeletionBeforeRestart) {
        await deleteBasketRequest();
      }

      navigateWithQueryParams(RESTART_ROUTE);
      navigate(0);
    },
    [deleteBasketRequest, navigateWithQueryParams, navigate, basketData]
  );

  return handleRestartBasket;
}

export { useRestartBooking };
